@import '~@fontsource/chivo/index.css';

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    min-height: 100vh;

    padding-top: calc(15vh); /* Vertically center at one-third of the screen height */
}

.message {
    text-align: center;
    padding: 20px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
