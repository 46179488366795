.right-panel-title {
    padding: 20px;
    display: flex;
    justify-content: space-between; /* This aligns items to each end (flex-start and flex-end) */
    align-items: center; /* This centers items vertically within the container */
}

.right-panel-user-circle-container {
    cursor: pointer;
    width: 28px; /* Adjust the width and height to make it a circle */
    height: 28px;
    background-color: green;
    border-radius: 50%; /* This makes it a circle by rounding the corners */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    margin-left: auto;
    margin-top: -4px;
    border: 2px solid #6eeaea; /* Add a 2px magenta border */
}

.right-panel-user-circle-text {
    color: white;
    margin: 0;
}

.right-panel-big-section {
    padding-right: 20px;
}

.right-panel-big-section-link {
    color: inherit;
    text-decoration: none;
}

.right-panel-big-section-text {
    color: #111;
    text-align: right;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
}

