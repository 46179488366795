.header {
    max-height: 50px;
    padding: 13px 20px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    background: #FFF;
    margin-bottom: 50px;
    min-width: 300px;
}

.logo-image {
    width: 200px;
}

.menu-icon {
    cursor: pointer;
}

.header-user-circle-container {
    cursor: pointer;
    width: 28px; /* Adjust the width and height to make it a circle */
    height: 28px;
    background-color: green;
    border-radius: 50%; /* This makes it a circle by rounding the corners */
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.header-user-circle-text {
    color: white;
    margin: 0;
}


.logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.market-data {
    white-space: nowrap; /* Prevent text from wrapping */
    overflow-x: auto; /* Enable horizontal scrolling */
    max-width: 100%; /* Ensure it doesn't exceed the screen width */
    display: flex;
    gap: 15px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.market-data::-webkit-scrollbar {
    width: 0.5em;
}

.market-data::-webkit-scrollbar-thumb {
    background-color: transparent;
}

/* Add CSS to style the market data items */
.market-data-item {
    display: flex;
    align-items: center; /* Vertically center align items */
    margin-right: 5px; /* Add some spacing between items if needed */
}

/* Style the market data text (the value) */
.market-data-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    padding-left: 3px;
    color: #777777;
}

.market-icon {
    width: 10px !important;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}