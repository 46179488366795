@import '~@fontsource/chivo/index.css';

.login-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    align-self: stretch;
    min-height: 360px;
}

.tabs {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
}

.active-tab {
    color: #111;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight:  bold;
    line-height: 130%; /* 13px */
    text-decoration-line: underline;
    text-transform: uppercase;
}

.inactive-tab {
    color: #565353;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 13px */
    text-decoration-line: none;
    text-transform: uppercase;
}

form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

input {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 13px */
    border-radius: 5px;
    background: #EEE;
    border: 1px solid #EEE; /* Add a thin border */
    padding: 8px;
    text-overflow: ellipsis;
}

input:focus {
    border-color: #757575 !important; /* Change border color when focused */
}

input:focus-visible {
    border-color: #757575 !important; /* Change border color when focused */
}

.remember-forgot {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust alignment */
    gap: 30px;
    font-size: 10px;
    align-self: stretch;
}

.remember {
    color: #757575;
    display: flex;
    align-items: center;
}

.remember input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.remember input[type="checkbox"] + label {
    position: relative;
    cursor: pointer;
    padding-left: 15px; /* Add space for the custom checkbox */
}

.remember input[type="checkbox"] + label::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    border-radius: 2px;
    border: 1px solid #757575; /* Black square border */
    background-color: transparent;
    transition: background-color 0.3s, border-color 0.3s;
}

.remember input[type="checkbox"]:checked + label::before {
    background-color: transparent; /* Set to transparent to leave margin */
    border-color: #000; /* Black square border when checked */
}

.remember input[type="checkbox"]:checked + label::after {
    content: "";
    position: absolute;
    left: 2px; /* Adjust for the margin */
    top: 2px; /* Adjust for the margin */
    width: 8px; /* 80% width of the checkbox */
    height: 8px; /* 80% height of the checkbox */
    border-radius: 2px;
    background-color: #000; /* Black square background when checked */
}
.forgot {
    display: flex;
    align-items: center;
    justify-content: flex-end; /* Align to the right */
    flex-grow: 1; /* Occupy remaining space */
}

.forgot a {
    color: #757575;
    text-decoration: none;
}

.recaptcha {
    margin: auto;
}

.login-button {
    width: 304px;
    align-self: center;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.login-button.disabled {
    cursor: default;
    background: #4b4a4a;
}

.validation-message {
    color: red;
    font-size: 12px;
    margin-top: -8px;
}