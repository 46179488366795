.title {
    text-align: center;
}

.center-container {
    display: flex;
    justify-content: center;
    /* Center horizontally */
    align-items: center;
    /* Center vertically */
}

.menu {
    display: flex;
    align-items: center;
    /* Center vertically */
    padding-left: 10px;
    padding-right: 10px;
    overflow-x: auto;
}

.menu p {
    margin-right: 20px;
    /* Add spacing between the elements if needed */
    text-align: center;
    /* Center horizontally */
    font-size: 12px;
    text-decoration: none;
    /* Remove underline */
    color: inherit;
    /* Use the parent's color (black by default) */
}

.selected {
    font-size: 12px;
    font-style: normal;
    border-radius: 5px;
    background: #EEE;
    border: 1px solid #EEE;
    /* Add a thin border */
    padding: 8px;
    text-overflow: ellipsis;
}

.link-custom {
    text-decoration: none;
    color: inherit;
}

.dashboard-container {
    min-width: 300px;
    max-width: 695px;
    margin: 0 auto;
    /* Center the container horizontally */
    padding-left: 10px;
    padding-right: 10px;
}

.rectangle {
    width: 100%;
    /* Make the rectangle width 100% of the container */
    height: 1px;
    background-color: #000;
    /* Set the color of the line (adjust as needed) */
    margin: 0 auto;
    /* Center the line horizontally within the container */
}

.dashboard-wallet-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 166.667% */
}

.dashboard-coin-wallet-text {
    color: #111;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 90%;
    /* 18px */
    letter-spacing: -0.6px;
}

.dashboard-wallet-info {
    display: flex;
    justify-content: space-between;
    /* Space items evenly within the container */
    align-items: center;
    /* Align items vertically in the middle */
}

.dashboard-coin-icon {
    width: 18px;
    height: 18px;
    text-align: center;
    align-items: center;
}

.dashboard-coin-info {
    display: flex;
    /* Make the text and amount flex items */
    flex-direction: row;
}

.dashboard-summary-price-text {
    width: 100px;
    color: #777;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.dashboard-summary-trade-button {
    border: none;
    border-radius: 4px;
    background: #111;
    color: white;
    box-shadow: none;
    height: 22px;
    cursor: pointer;
}

.trade-icon {
    width: 13px;
}

.trade-coin-container {
    border-radius: 5px;
    border: 1px solid #DDD;
    background: #FAFAFA;
    margin-bottom: 30px;
}

.trade-coin-container-line {
    display: flex;
    /* Make the text and amount flex items */
    flex-direction: row;
    justify-content: space-between;
}

.trade-container-coin {
    display: flex;
    align-items: center;
    /* Vertically center align items */
    padding: 8px;
    /* Add some spacing between items if needed */
    width: 60px;
    cursor: pointer;
}

.trade-container-coin-text {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    margin-right: 5px;
    margin-left: 5px;
    color: #777777;
}

.trade-ask-bid-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 30px;
}

@media (max-width: 600px) {
    .trade-ask-bid-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 10px;
    }
}

.trade-ask-bid-container-box {
    display: flex;
    /* Make the text and amount flex items */
    flex-direction: column;
    justify-content: space-between;
    text-align: center;
}

.trade-ask-bid-container-box-title {
    color: #111;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
}

.trade-ask-bid-container-box-value {
    color: #111;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.trade-ask-bid-container-buy-sell-coin {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.trade-ask-bid-container-buy-sell-coin-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 322px;
}

.trade-ask-bid-container-buy-sell-coin-text {
    color: #111;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.trade-ask-bid-container-buy-sell-coin-p-buy-sell {
    color: #111;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.trade-button {
    width: 304px;
    align-self: center;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.deposit-titles {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
    align-self: stretch;
    margin-bottom: 30px;
}

.deposit-active-tab {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 13px */
}

.deposit-inactive-tab {
    color: #565353;
    text-align: right;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 13px */
    text-decoration-line: none;
}

.deposit-select-type {
    color: #111;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    /* 13px */
    text-decoration-line: none;
}

.deposit-dropdown {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 13px */
    border-radius: 5px;
    border: 1px solid #EEE;
    /* Add a thin border */
    padding: 8px;
    text-overflow: ellipsis;
}

.deposit-bank-currency-sell-buy {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.deposit-bank-currency-sell-buy-box {
    display: flex;
    /* Make the text and amount flex items */
    flex-direction: column;
    text-align: center;
}

.deposit-bank-instruction-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
}

.deposit-bank-details-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 322px;
}

.deposit-bank-details-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.deposit-crypto-public-key-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.deposit-copy-to-clipboard-button {
    border-radius: 5px;
    border: 1px solid #111;
    background: #FFF;
    width: 200px;
    height: 30px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.deposit-get-tier-verified-button {
    width: 120px;
    align-self: center;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.deposit-get-verified-tier-container {
    padding-top: 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.deposit-get-verified-tier-box {
    justify-content: center;
    display: flex;
}

.copy-icon {
    width: 16px;
    /* Adjust the width and height to match your icon size */
    height: 16px;
    background-size: cover;
    margin-right: 5px;
    /* Optional margin to separate the icon from the text */
    margin-bottom: 2px;
}

.deposit-address-text {
    color: #111;
    font-size: 13px;
    font-style: normal;
    margin-top: -5px;
    margin-bottom: 40px;
}

.wallet-details-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: -15px;
    margin-bottom: 15px;
}

.wallet-details-exit-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    /* Add any additional styles for the close icon as needed */
}

.wallet-details-icon {
    width: 18px;
    height: 18px;
    text-align: center;
    align-items: center;
    margin-right: 10px;
}

.wallet-details-coin-box {
    flex-direction: row;
    justify-content: space-between;
    vertical-align: middle;
    width: 320px;
}

.button-with-text-only {
    background: none;
    /* Remove background color */
    border: none;
    /* Remove borders */
    box-shadow: none;
    /* Remove shadows */
    padding: 0;
    /* Optional: Remove padding */
    margin: 0;
    /* Optional: Remove margin */
    cursor: pointer;
    /* Add cursor style for interaction */
    /* You can add more styles here like font-size, text color, etc. */
}

.wallet-details-buttons {
    display: flex;
    justify-content: flex-end;
}

.wallet-details-text {
    color: #111;
    text-align: left;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 13px;
    /* 108.333% */
}

.account-tier-one-form-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.account-tier-one-form-box {
    flex: 0 0 49.5%;
    /* Each box occupies 45% of the parent width */
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Separate the inputs by 10px */
}

.tier-one-one-instruction-text {
    color: #757575;
    font-size: 10px;
    font-style: normal;
}

.account-files-table-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.account-files-table-upload-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
}

.account-files-table-text {
    text-align: left;
    overflow: hidden;
    color: #757575;
    text-overflow: ellipsis;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 13px */
}

.account-files-upload-button {
    width: 200px;
    align-self: center;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.account-files-actions-container {
    width: 100%;
    display: flex;
    gap: 15px;
    justify-content: left;
}

.account-request-verification-button-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.account-request-verification-button {
    width: 100%;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.account-validation-message {
    color: red;
    font-size: 10px;
    margin-top: -8px;
}

.account-tier-status-line {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.account-tier-status-text {
    color: #111;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 13px */
}

.account-checked-icon-container {
    vertical-align: middle;
    display: inline-block;
}

.account-checked-icon {
    width: 12px;
    height: 12px;
    vertical-align: middle;
    padding-top: 10px;
}

.account-verified-container-with-icon {
    display: flex;
    flex-direction: row;
    gap: 5px;
}

.account-tier-two-dropdown {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 13px */
    border-radius: 5px;
    background: #EEE;
    border: 1px solid #EEE;
    /* Add a thin border */
    padding: 8px;
    text-overflow: ellipsis;
}

.account-tier-two-form-box {
    flex: 0 0 49%;
    /* Each box occupies 45% of the parent width */
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* Separate the inputs by 10px */
}

.history-table-header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.history-table-header-text {
    color: #777;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 13px */
    cursor: pointer;
}

.history-table-header-export-buttons-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.history-table-container {
    max-width: 1500px;
    padding-top: 30px;
    margin: auto auto 50px;
    padding-left: 10px;
}

@media (max-width: 600px) {
    .history-table-container {
        overflow-x: scroll;
        /* Allow horizontal scrolling for the table container */
    }
}

.history-table-cell {
    border-bottom: 1px solid #ddd;
    /* Add a bottom border to each cell */
    padding-top: 15px;
    padding-bottom: 15px;
}

/* Add a pseudo-element to create a horizontal line under each row */
.history-table-row {
    color: #111;
    text-align: left;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    position: relative;
}

.history-table-thead-text {
    text-align: left;
    font-weight: normal;
}

.history-table-thead-hidden-section {
    margin-bottom: 5px;
    /* Adjust as needed */
}

.history-table-text-and-icon-header {
    display: flex;
    flex-direction: row;
}

.history-table-sort-icon-container {
    display: flex;
    align-items: center;
}

.history-table-sort-icon {
    padding-left: 5px;
    padding-bottom: 2px;
    height: 13px;
    width: 13px;
    cursor: pointer;
}

.history-pagination-container {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.history-pagination-buttons-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 10px
}

.history-pagination-button {
    width: 90px;
    font-size: 10px;
    background: #FAFAFA;
    color: #777777;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #DFDFDF;
    cursor: pointer;
}

.history-pagination-button-icon-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.history-pagination-icon-container {
    display: flex;
    align-items: center;
    padding-bottom: 2px;
}

.history-pagination-button-icon {
    width: 12px;
    height: 9px;
}

.history-current-page-size-dropdown {
    margin-top: 10px;
    width: 120px;
    align-self: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 13px */
    border-radius: 5px;
    border: 1px solid #EEE;
    /* Add a thin border */
    padding: 8px;
    text-overflow: ellipsis;
}

.withdrawal-bank-account-dropdown {
    width: 100%;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 13px */
    border-radius: 5px;
    background: #EEE;
    border: 1px solid #EEE;
    /* Add a thin border */
    padding: 8px;
    text-overflow: ellipsis;
}

.withdrawal-input-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 50px;
}

.withdrawal-validation-message {
    margin-top: 2px;
    color: red;
    font-size: 10px;
}

.withdrawal-button {
    width: 100%;
    align-self: center;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.order-history-titles {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-self: center;
}

.orders-no-orders-to-display-container {
    margin-top: 50px;
    text-align: center;
}

.orders-history-table-header-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.confirm-trade-modal-container {
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

.confirm-trade-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.confirm-trade-modal-row-text {
    font-size: 11px;
}

.order-validation-message {
    margin-top: -7px;
    color: red;
    font-size: 10px;
}

.loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid black;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
    margin-top: 35px;
}

.order-pending-cancel-loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-top: 4px solid black;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    animation: spin 1s linear infinite;
    align-self: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Center horizontally */
    justify-content: center;
    /* Center vertically */
    min-width: 300px;
    max-width: 695px;
    margin: 0 auto;
    /* Center the container horizontally */
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 60px;
}

.alert-modal-outer-box {
    display: flex;
    flex-direction: row;
    gap: 20px;
    width: 350px;
}

.alert-modal-inner-box {
    display: flex;
    flex-direction: column;
}

.alert-modal-title-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 15.6px */
    text-transform: uppercase;
}

.alert-modal-description-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.alert-modal-date-text {
    color: #777;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.alert-modal-icon {
    padding-top: 12px;
    width: 20px;
    height: 20px;
}

.mobile-activity-apps-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 15px;
}

.mobile-activity-app-button {
    width: 120px;
    height: 20px;
    padding-top: 0;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.mobile-activity-app-text {
    color: #FFF;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 13px */
    text-transform: uppercase;
}

.mobile-activity-icon {
    height: 18px;
    width: 18px;
    padding-bottom: 2px;
}

.mobile-activity-app-icon-and-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.referrals-text {
    color: #111;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 15.6px */
}

.referrals-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.help-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

.help-step-container {
    margin-bottom: 30px;
}

.help-step-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: -10px;
}

.help-step-title-text {
    color: #111;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    /* 18.2px */
    letter-spacing: -0.42px;
}

.help-step-description-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    letter-spacing: -0.36px;
    margin-left: 30px;
}

.help-step-icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-bottom: 2px;
}

.help-step-button {
    border: none;
    border-radius: 4px;
    background: #111;
    color: white;
    box-shadow: none;
    height: 22px;
    cursor: pointer;
    font-size: 10px;
    margin-left: 30px;
}

.terms-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 15px */
}

.terms-title-section {
    color: #111;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 150%;
    /* 15px */
    margin-top: 8px;
    margin-bottom: 8px;
}

.faq-title-section-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: normal;
    line-height: 130%;
    /* 15.6px */
    letter-spacing: -0.24px;
}

.faq-content-section-text {
    color: #111;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    /* 15.6px */
    letter-spacing: -0.12px;
    margin-left: 12px;
}