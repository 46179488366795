.settings-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.settings-section-title-text {
    color: #111;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
}

.settings-change-password-button {
    width: 100%;
    align-self: center;
    padding: 10px 20px;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.settings-section-text {
    color: #777;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.settings-newsletter-buttons-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.section-newsletter-button-selected {
    background: white;
    width: 84px;
    height: 33px;
    border-radius: 5px;
    border: 1px solid black;
    cursor: pointer;
}

.section-newsletter-button-unselected {
    background: white;
    color: #777777;
    width: 84px;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #777777;
    cursor: pointer;
}

.setting-section-two-factor-button-enabled {
    width: 49.5%;
    background: white;
    height: 33px;
    border-radius: 5px;
    border: 1px solid black;
    font-size: 10px;
    cursor: pointer;

}

.setting-section-two-factor-button-disabled {
    width: 49.5%;
    font-size: 10px;
    background: white;
    color: #777777;
    height: 33px;
    border-radius: 5px;
    border: 1px solid #777777;
    cursor: pointer;
}

.setting-section-two-factor-button-submit {
    width: 49.5%;
    background: #111;
    border-radius: 5px;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 10px;
    font-style: normal;
    line-height: 130%;
    font-weight: 400;
}

.setting-section-two-factor-buttons-container {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}