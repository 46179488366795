.left-panel-title {
    padding: 20px;
}


.left-panel-exit-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
}

.left-panel-big-section-link {
    color: inherit;
    text-decoration: none;
}

.left-panel-big-section-text {
    color: #111;
    text-align: left;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 26px */
}

.left-panel-big-section {
    padding-left: 20px;
}

.left-panel-small-section {
    padding-left: 20px;
}

.left-panel-small-section-text {
    padding-bottom: 10px;
    color: #111;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 13px */
}

.left-panel-icon-section {
    padding-left: 20px;
    margin-top: auto;
}

.left-panel-sm-icon {
    padding-right: 20px;
    width: 16px;
    height: 16px;
}

.left-panel-modal-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}